@import "bootstrap";

// @import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');

// @import "../appwork-admin-ui-kit/assets/css/bootstrap/bootstrap.min";
@import "../appwork-admin-ui-kit/css/classy-nav";
// @import "../appwork-admin-ui-kit/assets/css/font-awesome.min";
// @import "../appwork-admin-ui-kit/assets/css/et-line";
// @import "../appwork-admin-ui-kit/assets/css/elegant-icon";
// @import "../appwork-admin-ui-kit/assets/css/pe-icon-7-stroke";

@import "../appwork-admin-ui-kit/style";
@import "../appwork-admin-ui-kit/css/responsive";

@import "../bootstrap-datepicker/css/bootstrap-datepicker3";

@import "../select2/css/select2";

$fa-font-path: "../fontawesome/webfonts" !default;
@import "../fontawesome/scss/fontawesome";
@import "../fontawesome/scss/regular";
@import "../fontawesome/scss/solid";
// @import "../fontawesome/scss/brands";

// @import "bootstrap";


body {
    overflow-y: scroll;
    // background: $light;
    background-color: #f3f3f4;
    font-size: 14px;
}

.pt-70 {
    padding-top: 70px !important;
}

.container-fluid {
    max-width: 1600px;
}

#header {
    min-height: 100px;
    .navbar-brand img {
        width: auto;
        height: 40px;
        &.main-logo {
            height: 74px;
        }
    }
    .navbar-collapse {
        @include media-breakpoint-down(md) {
            margin-top: $grid-gutter-width/2;
        }
    }
    .nav-account-toggle {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        line-height: 1;
        font-size: 2.5em;
        color: $navbar-light-color;
        @include hover-focus() {
            color: $navbar-light-hover-color;
        }
        &:after {
            display: none;
        }
    }
    .navbar-nav .nav-link {
        @include media-breakpoint-up(md) {
            font-weight: bold;
        }
        @media (min-width: map-get($grid-breakpoints, "lg")) and (max-width: 1400px) {
            font-size: $font-size-sm;
        }
    }
}

.logo-trigger-area .logo {
    max-width: none;
    margin-left: 1rem;
    margin-right: 0;
    span {
        font-size: $font-size-base;
        font-weight: bold;
        color: white;
        @media (min-width: 360px) {
            font-size: $font-size-lg;
        }
    }
}
.user-meta-data .topbar-profile .user---thumb {
    i {
        font-size: 2.5em;
        color: $navbar-dark-color;
    }
    &:hover i {
        color: $navbar-dark-hover-color;
    }
}
.profile-data {
    padding: $grid-gutter-width/2 0;
}

.dark.sidebar-menu-on .classynav > ul > li > a {
    &.active,
    &:hover,
    &:focus {
        color: white;
    }
}

.select2-container{
    width: 100%!important;
}
.select2-container--default .select2-selection--single {
    border-color: #e5e6e7;
    border-radius: 0;
    outline: none;
    &:focus {
        border: solid #1ab394 1px;
    }
}
.select2-dropdown {
    border-color: #e5e6e7;
    border-radius: 0;
}

.table > thead > tr > th {
    white-space: nowrap !important;
}
.table > thead > tr > th > a {
    font-weight: bold;
}
.table thead th.orderable {
    &.asc:after {
        content: '\25B4'; // Arrow up
        color: $secondary;
    }
    &.desc:after {
        content: '\25BE'; // Arrow down
        color: $secondary;
    }
}
.table > tbody > tr > td i,
.table > tbody > tr > td span.true,
.table > tbody > tr > td span.false {
    font-size: 1rem;
}

.detail-content {
    // /** Tables **/
    table {
        border-right:0;
        clear: both;
        color: #333;
        margin-bottom: 10px;
        width: 100%;
    }
    th {
        border:0;
        border-bottom:2px solid #555;
        text-align: left;
        padding:4px;
    }
    th a {
        display: block;
        padding: 2px 4px;
        text-decoration: none;
    }
    th a.asc:after {
        content: ' ⇣';
    }
    th a.desc:after {
        content: ' ⇡';
    }
    table tr td {
        padding: 6px;
        text-align: left;
        vertical-align: top;
        border-bottom:1px solid #ddd;
    }
    table tr:nth-child(even) {
        background: #f9f9f9;
    }
    td.actions,
    td.actions-lines {
        text-align: center;
        white-space: nowrap;
    }
    table td.actions a,
    table td.actions-lines a {
        margin: 0px 6px;
        padding:2px 5px;
    }
    table td.actions-lines a {
        margin: 0;
        display: block;
    }
    td.nowrap {
        text-align: center;
        white-space: nowrap;
    }
    table.form-table,
    table.border-table {
        border-right:0;
        clear: both;
        color: #333;
        margin-bottom: 10px;
        width: 100%;
        th {
            border:0;
            border-bottom:2px solid #555;
            text-align: left;
            padding:4px;
        }
        th a {
            display: block;
            padding: 2px 4px;
            text-decoration: none;
        }
        th a.asc:after {
            content: ' ⇣';
        }
        th a.desc:after {
            content: ' ⇡';
        }
        tr td {
            padding: 6px;
            text-align: left;
            vertical-align: top;
            border-bottom:1px solid #ddd;
        }
        tr:nth-child(even) {
            background: #f9f9f9;
        }
        td.actions,
        td.actions-lines {
            text-align: center;
            white-space: nowrap;
        }
        td.actions a,
        td.actions-lines a {
            margin: 0px 6px;
            padding:2px 5px;
        }
        td.actions-lines a {
            margin: 0;
            display: block;
        }
        td.nowrap {
            text-align: center;
            white-space: nowrap;
        }
    }

    table.form-table {
        border: none;
        border-spacing: 10px;
    }
    table.form-table tr td, table.form-table tr th {
        padding: 0;
        margin: 0;
        border: none;
        vertical-align: top;
    }
    table.form-table tr:nth-child(even) {
        background: inherit;
    }
    table.form-table .input {
        margin: 0;
    }
    table.form-table dl {
        width: 100%;
    }
    // table.row-hover tr:hover {
    //     background-color: #B5CDFF;
    // }


    /** Views **/
    table.border-table {
        margin-bottom: 50px;
        border-collapse: collapse;
        border-top: 1px solid #DDD;
        border-bottom: 1px solid #DDD;
    }
    table.border-table tr,
    table.border-table th {
        border-width: 1px;
    }
    table.border-table h2 {
        font-size: 12pt;
        color: #fff;
        background-color: #666;
        font-weight: bold;
        margin: 0;
    }
    table.border-table .title {
        background-color: #666;
        color: #fff;
    }
    table.border-table .fieldname {
        font-weight: bold;
        color: #000;
        font-size: 10pt;
    }
    table.border-table .right {
        text-align: right;
    }

    .detail-content {
        table {
            width: 100%;
            tr td {
                vertical-align: top;
            }
        }
    }
}

.dark.classy-nav-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    background: transparent;
    .classy-navbar {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .classy-menu {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            background: transparent !important;
        }
    }
    .classynav {
        padding-bottom: 120px;
        ul > li > a {
            background: transparent !important;
            > i {
                width: 25px;
                text-align: center;
            }
        }
    }
}

.menu-start-open-toggle {
    margin-top: 15px;
    border-bottom: none !important;
    text-align: center;
    opacity: 0.25;
}

.layout-container-area .menu-backdrop {
    display: none;
}
@media (max-width: 991px) {
    .menu-start-open-toggle {
        display: none !important;
    }
    .side-menu-open .layout-container-area .menu-backdrop {
        content: '';
        position: fixed;
        z-index: 1000;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #333;
        opacity: 0.6;
    }
}
.side-menu-area {
    z-index: 1001;
    left: -280px;
}

.side-menu-open .side-menu-area {
    left: 0;
}

.sidemenu-container {
    margin-left: 0;
}

.side-menu-open .sidemenu-container {
    margin-left: 0;
}

@media (min-width: 992px) {
    .side-menu-area {
        z-index: 500;
        left: 0;
        width: 55px;
    }

    .side-menu-open .side-menu-area,
    .side-menu-start-open .side-menu-area {
        width: 260px;
    }

    .sidemenu-container {
        margin-left: 55px;
    }

    .side-menu-open .sidemenu-container,
    .side-menu-start-open .sidemenu-container {
        margin-left: 260px;
    }

    .classynav {
        padding-bottom: 120px;
        ul > li > a {
            white-space: nowrap;
            background: transparent !important;
            > i {
                width: 25px;
                text-align: center;
            }
            span {
                display: none;
                .side-menu-open &,
                .side-menu-start-open & {
                    display: inline;
                }
            }
        }
    }
}
